<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="insurance"
                          columnsPrefix="insurance.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :tbody-tr-class="rowClass"
                          title="insurance.title.insurance"
                >
                    <template #cell(number)="row">
                        <a @click.prevent="$root.$children[0].openModal('kva-modal', row.item.offering_id)"
                           href="#">{{ row.item.number }}</a>
                    </template>

                    <template #cell(net_amount)="row">
                        {{ row.item.net_amount | priceFormat }} &euro;
                    </template>

                    <template #cell(vat_amount)="row">
                        {{ row.item.vat_amount | priceFormat }} &euro;
                    </template>

                    <template #cell(vat_rate)="row">
                        {{ row.item.vat_rate }}%
                    </template>

                    <template #cell(total_amount)="row">
                        {{ row.item.total_amount | priceFormat }} &euro;
                    </template>

                    <template #cell(actions)="row">
                        <b-button
                            @click="openInsurance(row.item)"
                            size="sm"
                            variant="info"
                            class="mr-2"
                            :title="$t('common.title.edit')"
                        >
                            <font-awesome-icon icon="edit"/>
                        </b-button>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'Insurance',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Insurance', ['getInsurancesTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Insurance/fetchInsurances', 'getInsurancesTable');
        },
        openInsurance(insurance) {
            this.$root.$children[0].openModal('insurance-modal', {
                    id: insurance.id,
                }, this.refreshTable,
                {width: '800px'}
            )
        },
        rowClass(item, type) {
            if (item) {
                if (item.file && item.insurance) return 'table-success'
            }
        },
    },
}
</script>